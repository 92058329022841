import { FC, ReactNode, useMemo } from "react";
import joinClassNames from "classnames";

import { AmountTypes, ClickFuncParams } from "./duck/types";
import { getAmountData, getAmountTypesBool } from "./duck/selectors";
import { BaseProps } from "../duck/types";

import classes from "../styles/classes.module.scss";

interface Props extends BaseProps {
  type?: AmountTypes;
  tokenCode?: string | ReactNode; // ReactNode use only <span> with styles
  minShortenKMB?: number;
  customPrefix?: string;
  isCalculate?: boolean;
  onClick?: (params: ClickFuncParams) => void;
  classNames?: Partial<{
    zeros: string;
  }>;
}

const Amount: FC<Props> = ({
  value,
  tokenCode,
  precision,
  symbol,
  exchangeRate,
  type = "default",
  minShortenKMB,
  customPrefix,
  onClick,
  className,
  isCalculate = true,
  classNames = {},
}) => {
  const { isSummary, isEquivalent, isInteger } = getAmountTypesBool(type);

  const amountForFormat =
    isCalculate && (isEquivalent || (isSummary && !tokenCode))
      ? value.times(exchangeRate)
      : value;

  const {
    amountPart,
    amountString,
    bigAmount,
    postfix,
    prefixLess,
    prefixSymbol,
    zerosPart,
  } = useMemo(
    () =>
      getAmountData({
        precision,
        minShortenKMB,
        symbol,
        tokenCode,
        type,
        value: amountForFormat,
      }),
    [amountForFormat, minShortenKMB, precision, symbol, tokenCode, type],
  );

  if (isEquivalent && tokenCode) {
    return "Equivalent type should be in fiat currency!";
  }

  if (isInteger && !Number.isInteger(amountForFormat.toNumber())) {
    return "Value is not integer!";
  }

  return (
    <span
      className={joinClassNames(classes.wrapper, className)}
      onClick={onClick && (() => onClick({ amountString, bigAmount }))}
    >
      {prefixLess}
      <span dir="ltr">{customPrefix || prefixSymbol}</span>
      {amountPart}
      <span className={classNames.zeros}>{zerosPart}</span>
      {postfix && <> {postfix}</>}
    </span>
  );
};

export default Amount;
