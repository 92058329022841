import Big from "bignumber.js";

import { DEFAULT_SHORTEN_KMB } from "constants/numbers";

import { getNumberData } from "selectors/numbers";

import { AmountTypes, GetAmountDataParams, GetAmountDataReturn } from "./types";

export const getAmountTypesBool = (type: AmountTypes) => ({
  isDefault: type === "default",
  isEquivalent: type === "equivalent",
  isInteger: type === "integer",
  isInteractive: type === "interactive",
  isSummary: type === "summary",
});

// For equivalent or summary use, symbol params is required
export const getAmountData = ({
  value = Big(0),
  type = "default",
  tokenCode,
  symbol,
  precision,
  minShortenKMB = DEFAULT_SHORTEN_KMB,
}: GetAmountDataParams): GetAmountDataReturn => {
  const { isDefault, isInteger, isSummary, isEquivalent, isInteractive } =
    getAmountTypesBool(type);

  const {
    numberFormatted,
    numberString,
    numberBig,
    numberPart,
    zerosPart,
    minPrecisionAmount,
    isValueTooSmall,
  } = getNumberData({
    value,
    minShortenKMB,
    precision,
    isKMB: isSummary || minShortenKMB > DEFAULT_SHORTEN_KMB,
    isExternal: isSummary || isEquivalent,
    isInteger,
    isInteractive,
  });

  const isSymbolHidden = tokenCode || isDefault || isInteger || isInteractive;
  const prefixLess = isValueTooSmall ? "< " : "";
  const prefixSymbol = isSymbolHidden ? "" : symbol;
  const prefix = isSymbolHidden ? `${prefixLess}` : `${prefixLess}${symbol}`;
  const amountFormatted =
    isValueTooSmall && !isInteger
      ? `${prefixLess}${prefixSymbol}${minPrecisionAmount}`
      : `${prefixSymbol}${numberFormatted}`;

  return {
    amountFormatted,
    amountPart: numberPart,
    amountString: numberString,
    bigAmount: numberBig,
    postfix: tokenCode,
    prefix,
    prefixLess,
    prefixSymbol,
    zerosPart,
  };
};
