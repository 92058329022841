import Big from "bignumber.js";

import { getNumberData } from "selectors/numbers";

import { GetPriceDataParams, GetPriceDataReturn } from "./types";

// For equivalent or summary use, symbol params is required
export const getPriceData = ({
  symbol,
  precision,
  tokenCode,
  value = Big(0),
  isEquivalent = false,
}: GetPriceDataParams): GetPriceDataReturn => {
  const {
    numberFormatted,
    numberBig,
    numberPart,
    zerosPart,
    minPrecisionAmount,
    isValueTooSmall,
  } = getNumberData({
    value,
    precision,
    isExternal: Boolean(!tokenCode),
    isPrice: true,
    isKMB: true,
  });

  const isSymbolHidden = tokenCode || !isEquivalent;
  const prefixLess = isValueTooSmall ? "< " : "";
  const prefixSymbol = isSymbolHidden ? "" : symbol;
  const prefix = isSymbolHidden ? `${prefixLess}` : `${prefixLess}${symbol}`;
  const priceFormatted = isValueTooSmall
    ? `${prefixLess}${prefixSymbol}${minPrecisionAmount}`
    : `${prefixSymbol}${numberFormatted}`;

  return {
    bigPrice: numberBig,
    postfix: tokenCode,
    prefix,
    prefixLess,
    prefixSymbol,
    priceFormatted,
    pricePart: numberPart,
    zerosPart,
  };
};
