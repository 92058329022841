import { FC } from "react";
import Big from "bignumber.js";
import joinClassNames from "classnames";

import { tryToBig } from "selectors/numbers";

import useSelector from "hooks/redux/use-selector";

import { selectCurrency } from "store/account/duck/selectors";

import { BaseProps } from "./duck/types";

import classes from "./styles/classes.module.scss";

interface Props {
  value?: Big | number | string | null;
  isWithoutDash?: boolean;
  currencyName?: string;
}

export function constructNumberComponent<P extends BaseProps>(
  Comp: FC<P>,
): FC<Props & Omit<P, "symbol" | "exchangeRate" | "value">> {
  return ({
    value,
    isWithoutDash = false,
    className,
    currencyName,
    ...rest
  }) => {
    const { symbol, rate } = useSelector(state =>
      currencyName
        ? state.currencies.entities[currencyName]
        : selectCurrency(state),
    );
    const bigValue = tryToBig(value);
    if (isWithoutDash && !bigValue) {
      return null;
    }

    if (!bigValue) {
      return (
        <span className={joinClassNames(classes.zeroDash, className)}>—</span>
      );
    }

    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <Comp
        value={bigValue}
        className={className}
        exchangeRate={rate}
        symbol={symbol}
        {...rest}
      />
    );
  };
}
