import Big from "bignumber.js";

import { getNumberData, getIsTooSmallForPrecision } from "selectors/numbers";

import {
  GetPercentDataParams,
  GetPercentDataReturn,
  PolarityTypes,
} from "./types";

export const getPercentData = ({
  value = Big(0),
  precision = 2,
  isRatio = false,
}: GetPercentDataParams): GetPercentDataReturn => {
  const bigPercent = isRatio ? value.times(100) : value;
  const bigRatio = isRatio ? value : value.div(100);
  let polarity: PolarityTypes = "neutral";

  if (bigPercent.gt(0)) {
    polarity = "positive";
  } else if (bigPercent.lt(0)) {
    polarity = "negative";
  }

  const roundedPercent = bigPercent
    .decimalPlaces(precision, Big.ROUND_HALF_UP)
    .abs();

  const { isValueTooSmall, minPrecisionAmount } = getIsTooSmallForPrecision(
    // Rounding here because we should compare positive numbers, percent can be negative
    bigPercent.abs(),
    precision,
  );

  const valueToPercent = isValueTooSmall ? minPrecisionAmount : roundedPercent;
  const isZero = valueToPercent.eq(0);
  const { numberFormatted, numberString } = getNumberData({
    value: valueToPercent,
    precision: isZero ? 2 : precision,
  });
  const prefix = `${isValueTooSmall ? `< ` : ""}`;
  const percentFormatted = `${prefix}${numberFormatted}%`;

  return {
    bigPercent: roundedPercent,
    bigRatio,
    polarity,
    percentFormatted,
    percentString: `${numberString}%`,
    prefix,
  };
};
