import AmountComp, { amountSelectors } from "./amount";
import PercentComp, { percentSelectors } from "./percent";
import PriceComp, { priceSelectors } from "./price";

import { constructNumberComponent } from "./NumberConstructor";

export const Price = constructNumberComponent(PriceComp);
export const Amount = constructNumberComponent(AmountComp);
export const Percent = constructNumberComponent(PercentComp);

export { amountSelectors, percentSelectors, priceSelectors };
