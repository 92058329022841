import { FC, useMemo } from "react";
import joinClassNames from "classnames";

import ArrowIcon from "icons/arrow-up.svg?react";

import { getPercentData } from "./duck/selectors";
import { BRACKETS } from "./duck/constants";
import { BracketsTypes, PercentTypes } from "./duck/types";
import { BaseProps } from "../duck/types";

import classes from "./styles/classes.module.scss";

interface Props extends BaseProps {
  isRatio?: boolean;
  type?: PercentTypes;
  bracketsType?: BracketsTypes;
  isPrefix?: boolean;
}

const Percent: FC<Props> = ({
  value,
  type = "default",
  isRatio = false,
  bracketsType = "none",
  precision = 2,
  isPrefix = false,
  className,
}) => {
  const { polarity, percentString, prefix } = useMemo(
    () =>
      getPercentData({
        isRatio,
        precision,
        value,
      }),
    [isRatio, precision, value],
  );

  const isIcon = type === "markets";

  return (
    <span
      data-theme={type}
      data-polarity={polarity}
      className={joinClassNames(
        classes.themePresets,
        classes.percent,
        className,
      )}
    >
      {BRACKETS[bracketsType].left}
      {isIcon && (
        <ArrowIcon data-polarity={polarity} className={classes.arrowIcon} />
      )}
      {isPrefix && !isIcon && prefix}
      {percentString}
      {BRACKETS[bracketsType].right}
    </span>
  );
};

export default Percent;
