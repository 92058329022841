export const BRACKETS = {
  angle: {
    left: "<",
    right: ">",
  },
  curly: {
    left: "{",
    right: "}",
  },
  none: {
    left: "",
    right: "",
  },
  parentheses: {
    left: "(",
    right: ")",
  },
  square: {
    left: "[",
    right: "]",
  },
};
